/* Animations */
.header__burger.open .header__burger-bars {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}

.header__burger.open .header__burger-bars::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.header__burger.open .header__burger-bars::after {
    transform: rotate(-45deg) translate(35px, 35px);
}


/* Component */
.header__burger {
    position: relative;
    display: flex;
    align-items: center;
    width: var(--size-2xl);
    height: var(--size-2xl);
    margin: auto 0;
    padding-right: 8px;

    cursor: pointer;
    transition: all .5s ease-in-out;
}

.header__burger-bars {
    background: var(--clr-black);
    left: -8px;
    width: var(--size-lg50);
    height: 3px;

    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(225, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
}

.header__burger-bars::before,
.header__burger-bars::after {
    content: '';
    position: absolute;
    background: var(--clr-black);
    height: 3px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(225, 101, 47, 0.2);

    transition: all 0.5s ease-in-out;
}

.header__burger-bars::before {
    width: var(--size-2xl);
    transform: translateY(-10px);
}

.header__burger-bars::after {
    width: var(--size-base);
    transform: translateY(10px);
}

/* laptop-1x */
@media (min-width: 1024px) {
    .header__burger {
        display: none;
    }
}