.carousel {
    width: 100% !important;
}

.slideshow__img {
    border-radius: 16px;
    object-fit: contain;
    max-height: 80vh;
}

.carousel .control-dots {
    bottom: 24px !important;
}

.carousel .control-dots .dot {
    width: 64px !important;
    border-radius: 4px !important;
}

.carousel .thumbs-wrapper {
    margin: 8px 0 !important;
}

.carousel .thumb {
    padding: 0 !important;
    margin-right: 8px !important;
}

.thumb {
    object-fit: cover;
    object-position: 50% 0%;
    border-radius: 0;
    border: none !important;
}

.thumb img {
    height: 100px;
    object-fit: cover !important;
}
