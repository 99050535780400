.intro__card {
    display: flex;
    flex-flow: column;
}

.intro__card_content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap: auto;
    padding: 16px;

    flex-grow: 1;
}
