.news_events_resources__card {
    background-color: var(--clr-light-background);
    border-radius: 16px;
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;

    transition: box-shadow 1s;
    /* height: 500px;
    min-height: 500px; */
}
.news_events_resources__card:hover {
    box-shadow: #00000025 8px 8px 16px 0;
}
/* tablet */
@media (min-width: 768px) {
    .news_events_resources__card {
        flex-direction: row;
    }
    .news_events_resources__card_lg {
        /* height: 267px; */
        height: fit-content;
        /* min-height: 267px; */
    }
    .news_events_resources__card_md {
        /* height: 203px; */
        height: fit-content;
        /* min-height: 203px; */
    }
    .news_events_resources__card_sm {
        /* height: 231px; */
        height: fit-content;
        /* min-height: 231px; */
    }
}
.news_events_resources__card_content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    gap: 16px;
}