.mobile-nav__screen {
    background-color: var(--clr-dark-background);
    display: none;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    position: fixed;
    gap: 38vh;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    text-align: center;
    z-index: 997;
}

.mobile-nav__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30vh;
    padding: 0;
    
    list-style: none;
    gap: var(--size-2xl);
}

.mobile-nav__menu-li {
    color: var(--clr-white);
    text-decoration: none;
}
