.statistics__card {
    background-color: var(--clr-white);
    color: var(--clr-black);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    text-align: center;
}

/* tablet */
@media (min-width: 768px) {
    .statistics__card {
        width: 33%;
    }
}