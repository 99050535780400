header {
    background-color: var(--clr-white);
    position: sticky;
    top: 0px;

    z-index: 998;
}
header.scrolled {
    background-color: var(--clr-white);
    /* backdrop-filter: blur(8px);
    background: linear-gradient(94deg, rgba(255, 255, 255, 0.75)-0.03%, rgba(255, 255, 255, 0.45)99.97%); */
}

.header__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.header__left {
    display: flex;

    gap: var(--size-xs);
}

.header__nav {
    display: none;
}

.header__right {
    display: block;
}

.header__left_logo {
    width: 150px;
    margin: auto;
}

.header__right_logo {
    display: none;
}

/* md */
@media (min-width: 768px) {
    .header__nav-left {
        height: auto;
    }

    .header__logo {
        margin: 0;
    }

    .header__right_logo {
        display: block;
    }
}

/* laptop-1x */
@media (min-width: 1024px) {
    .header__burger {
        display: none;
    }
            
    .header__nav {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        margin: auto 0px;

        list-style: none;
        gap: 8px;
    }
}

/* desktop-1x */
@media (min-width: 1440px) {
    .header__left_logo {
        width: 262px;
    }
}