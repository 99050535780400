.hero__content {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 85vw;
    transform: translate(-50%, -50%);
    padding: 24px;

    z-index: 10;
    border-radius: 16px;
    backdrop-filter: blur(8px);
    background: linear-gradient(94deg, rgba(255, 255, 255, 0.75)-0.03%, rgba(255, 255, 255, 0.45)99.97%);
    border: 1px solid black;
}

/* laptop-1x */
@media (min-width: 1024px) {
    .hero__content {
        padding: 32px 48px;
    }
}