.footer__main {
    text-align: center;
}

.footer__links_ul li {
    padding: 4px 8px;
}

.footer__social_icon {
    color: var(--clr-white);
    width: 28px;
    height: 28px;
}